var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-button',{on:{"click":function($event){_vm.showModal = !_vm.showModal, _vm.contactData = {}, _vm.selected = []}}},[_vm._v("Agregar/Editar Staff")]),_c('b-modal',{attrs:{"size":"lg","title":_vm.editeItem === '' ? 'Agregar personal de Staff': 'Editar personal de Staff'},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"float-right",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){_vm.showModal=false}}},[_vm._v(" No ")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"success","size":"sm"},on:{"click":_vm.validate}},[_vm._v(" Guardar ")])]},proxy:true}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(_vm.initTransaction)?_c('div',[_c('loader-process',{attrs:{"message":this.messageInformation}})],1):_c('div',[_c('div',[_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"col":"","lg":"6","sm":"12"}},[_c('ValidationObserver',{ref:"observerFilter",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.filterClient)}}},[_c('label',{staticClass:"ml-2",attrs:{"for":"input-small"}},[_vm._v("Buscar por CEDULA")]),_c('ValidationProvider',{attrs:{"name":"Valor de"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"size":"sm"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',[_c('i',{staticClass:"ri-bookmark-2-line"})])]},proxy:true}],null,true)},[_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"number","id":"nitSearchFormControl","placeholder":"Ingrese NIT sin digito de verficación"},model:{value:(_vm.contactData.identificationObjectNumber),callback:function ($$v) {_vm.$set(_vm.contactData, "identificationObjectNumber", $$v)},expression:"contactData.identificationObjectNumber"}}),_c('b-button',{staticClass:"ml-3",attrs:{"variant":"primary","text":"","type":"submit"}},[_vm._v(" Buscar "),_c('i',{staticClass:"ri-search-eye-line ml-2"})]),_c('div',{staticClass:"invalid-feedback ml-2"},[_c('span',[_vm._v(_vm._s(errors[0]))])])],1)]}}],null,true)})],1)]}}])})],1)],1)],1),_c('label',{staticClass:"ml-2",attrs:{"for":"input-small"}},[_vm._v("Buscar por Nombre")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"search-wrapper panel-heading col-sm-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQueryName),expression:"searchQueryName"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Buscar por Nombre"},domProps:{"value":(_vm.searchQueryName)},on:{"click":function($event){return _vm.getNameIndex()},"input":function($event){if($event.target.composing){ return; }_vm.searchQueryName=$event.target.value}}})])]),_c('div',{staticClass:"table-responsive"},[(_vm.resourcesName.length)?_c('table',{staticClass:"table"},[_c('tbody',_vm._l((_vm.resultQueryName.slice(0, 5)),function(item){return _c('tr',{key:item.id},[_c('td',[_c('a',{attrs:{"href":item.uri},on:{"click":function($event){_vm.agregarName(item), _vm.filterClient(item.nit)}}},[_vm._v(_vm._s(item.name))])])])}),0)]):_vm._e()]),_c('ValidationObserver',{ref:"observerContact",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.confirmModal)}}},[_c('b-row',_vm._l((_vm.inputs1),function(input){return _c('b-col',{key:input.index,staticClass:"mb-3",attrs:{"cols":"12","sm":"6"}},[_c('label',{staticClass:"ml-2",attrs:{"for":"input-small"}},[_vm._v(_vm._s(input.name))]),_c('ValidationProvider',{attrs:{"name":input.name,"rules":input.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(input.type === 'text' || input.type === 'number')?_c('b-input-group',{attrs:{"size":"sm"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',[_c('i',{class:input.iconClass})])]},proxy:true}],null,true)},[_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"id":input.name},model:{value:(_vm.contactData[input.dataModel]),callback:function ($$v) {_vm.$set(_vm.contactData, input.dataModel, $$v)},expression:"contactData[input.dataModel]"}}),_c('div',{staticClass:"invalid-feedback ml-2"},[_c('span',[_vm._v(_vm._s(errors[0]))])])],1):_vm._e()]}}],null,true)}),(input.type === 'select')?_c('b-input-group',{attrs:{"size":"sm"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',[_c('i',{staticClass:"ri-bookmark-2-line"})])]},proxy:true}],null,true)},[_c('b-form-select',{attrs:{"options":input.options,"type":"text","id":"docTypeFormControl"},model:{value:(_vm.contactData[input.dataModel]),callback:function ($$v) {_vm.$set(_vm.contactData, input.dataModel, $$v)},expression:"contactData[input.dataModel]"}})],1):_vm._e()],1)}),1)],1)]}}])}),[_c('div',[_c('b-form-group',{attrs:{"label":"habilidades:"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{attrs:{"id":"checkbox-group-1","options":_vm.options,"aria-describedby":ariaDescribedby,"name":"flavour-1","switches":"","stacked":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]}}])}),_c('div',[_vm._v("Selected: "),_c('strong',[_vm._v(_vm._s(_vm.selected))])])],1)]],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }