<template>
  <b-container fluid>
    <b-row>
      <b-col >
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">STAFF</h4>
          </template>
          <template v-slot:headerAction>
            <modal-property :inputs1="inputs1" :editeItem="editeItem" @cleanUpdateModal="cleanUpdateModal" @saveModal="saveModal"></modal-property>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col cols="12" >
                 <div>
                  <b-tabs @input="onTabchange" v-model="tabIndex" class="nav-fill" >
                    <!-- CONTENEDOR DE INFORMACION -->
                    <div v-if="tabIndex == 0">
                      <div>
                        <p class="h3">TODO EL EQUIPO DE STAFF</p>
                      </div>
                      <b-row>
                        <b-col cols="12" md="3">
                          <p class="h6">Total: {{getTotalStaff}} </p>
                        </b-col>
                        <b-col cols="12" md="3">
                            <p>
                                <i class="fas fa-car"></i>
                                <strong class="ml-1">{{getTotalConductores}} Conductores</strong>
                            </p>
                            <p>
                                <i class="fas fa-volume-up"></i>
                                <strong class="ml-1">{{getTotalSonido}} Operarios de Sonido</strong>
                            </p>
                        </b-col>
                        <b-col cols="12" md="3">
                            <p>
                                <i class="fas fa-file-signature"></i>
                                <strong class="ml-1">{{getTotalRegistro}} Registro de Asistencia</strong>
                            </p>
                            <p>
                                <i class="fas fa-chalkboard-teacher"></i>
                                <strong class="ml-1">{{getTotalVirtual}} Asambleas Virtuales</strong>
                            </p>
                        </b-col>
                        <b-col cols="12" md="3">
                            <p>
                                <i class="fas fa-headset"></i>
                                <strong class="ml-1">{{getTotalSoporte}} Soporte</strong>
                            </p>
                            <p>
                                <i class="fas fa-file-word"></i>
                                <strong class="ml-1">{{getTotalActa}} Actas</strong>
                            </p>
                            <p>
                                <i class="fas fa-plug"></i>
                                <strong class="ml-1">{{getTotalConexion}} Conexion</strong>
                            </p>
                        </b-col>
                      </b-row>
                    </div>
                    <div v-if="tabIndex == 1">
                      <div>
                        <p class="h3">CONDUCTORES </p>
                      </div>
                      <div class="d-sm-flex pt-3">
                        <div class="px-4">
                          <p class="h6">Total: {{getTotalConductores}} </p>
                        </div>
                      </div>
                    </div>
                    <div v-if="tabIndex == 2">
                      <div>
                        <p class="h3">OPERARIOS DE SONIDO</p>
                      </div>
                      <div class="d-sm-flex pt-3">
                        <div class="px-4">
                          <p class="h6">Total: {{getTotalSonido}} </p>
                        </div>
                      </div>
                    </div>
                    <div v-if="tabIndex == 3">
                      <div>
                        <p class="h3">REGISTRO DE ASISTENCIA</p>
                      </div>
                      <div class="d-sm-flex pt-3">
                        <div class="px-4">
                          <p class="h6">Total: {{getTotalRegistro}} </p>
                        </div>
                      </div>
                    </div>
                    <div v-if="tabIndex == 4">
                      <div>
                        <p class="h3">ASAMBLEAS VIRTUALES</p>
                      </div>
                      <div class="d-sm-flex pt-3">
                        <div class="px-4">
                          <p class="h6">Total: {{getTotalVirtual}} </p>
                        </div>
                      </div>
                    </div>
                    <div v-if="tabIndex == 5">
                      <div>
                        <p class="h3">SOPORTE</p>
                      </div>
                      <div class="d-sm-flex pt-3">
                        <div class="px-4">
                          <p class="h6">Total: {{getTotalSoporte}} </p>
                        </div>
                      </div>
                    </div>
                    <div v-if="tabIndex == 6">
                      <div>
                        <p class="h3">ACTAS</p>
                      </div>
                      <div class="d-sm-flex pt-3">
                        <div class="px-4">
                          <p class="h6">Total: {{getTotalActa}} </p>
                        </div>
                      </div>
                    </div>
                    <div v-if="tabIndex == 7">
                      <div>
                        <p class="h3">CONEXION</p>
                      </div>
                      <div class="d-sm-flex pt-3">
                        <div class="px-4">
                          <p class="h6">Total: {{getTotalConexion}} </p>
                        </div>
                      </div>
                    </div>
                    <!-- TAB Proveedores -->
                    <b-tab  title-item-class="mytab"  active>
                      <template #title >
                        <div>
                         <i class="fas fa-people-arrows fa-3x"></i>
                        <p class="text-center"><strong>Proveedores</strong></p>
                        </div>
                      </template>
                      <b-row>
                        <b-col sm="12">
                        </b-col>
                      </b-row>
                    </b-tab>
                    <!-- TAB Conductores -->
                    <b-tab >
                      <template #title>
                           <i class="fas fa-car fa-3x"></i>
                        <p class="text-center"><strong>Conductores</strong></p>
                      </template>
                    </b-tab>
                    <!-- TAB Operario Sonido -->
                    <b-tab >
                      <template #title>
                        <i class="fas fa-volume-up fa-3x"></i>
                        <p class="text-center"><strong>Operarios de Sonido</strong></p>
                      </template>
                    </b-tab>
                    <!-- TAB Registro de Asistencia-->
                    <b-tab>
                      <template #title>
                        <i class="fas fa-file-signature fa-3x"></i>
                        <p class="text-center"><strong>Registro de Asistencia</strong></p>
                      </template>
                    </b-tab>
                    <!-- TAB Asambleas Virtuales -->
                    <b-tab>
                      <template #title>
                        <i class="fas fa-chalkboard-teacher fa-3x"></i>
                        <p class="text-center"><strong>Asambleas Virtuales</strong></p>
                      </template>
                    </b-tab>
                    <!-- TAB Soporte -->
                    <b-tab>
                      <template #title>
                        <i class="fas fa-headset fa-3x"></i>
                        <p class="text-center"><strong>Soporte</strong></p>
                      </template>
                    </b-tab>
                    <!-- TAB Actas-->
                    <b-tab>
                      <template #title>
                        <i class="fas fa-file-word fa-3x"></i>
                        <p class="text-center"><strong>Actas</strong></p>
                      </template>
                    </b-tab>
                    <!-- Tab Conexion-->
                    <b-tab>
                      <template #title>
                        <i class="fas fa-plug fa-3x"></i>
                        <p class="text-center"><strong>Conexion</strong></p>
                      </template>
                    </b-tab>
                  </b-tabs>
                </div>
                <iq-card>
                  <template v-slot:headerTitle>
                  </template>
                  <template v-slot:body>
                    <ag-grid-vue
                      id="ag-grid"
                      class="ag-theme-material border height-500"
                      :columnDefs="staffHeaders"
                      :rowData="dataToShow"
                      :postSort="postSort"
                      :defaultColDef="defaultColDef"
                      :animateRows="true"
                      :groupDefaultExpanded="groupDefaultExpanded"
                      :getDataPath="getDataPath"
                      :autoGroupColumnDef="autoGroupColumnDef"
                      :floatingFilter="true"
                      :modules="modules"
                      rowSelection="single"
                      :resizable="true"
                      :pagination=true
                      :paginationPageSize="paginationPageSize"
                      :enableCellTextSelection="true"
                      @rowDoubleClicked="onRowClickedAllProperties"
                      style="width: 100%; height: 80vh"
                    ></ag-grid-vue>
                  </template>
                </iq-card>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import 'ag-grid-community/dist/styles/ag-grid.min.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import ModalProperty from './ModalStaff.vue'
import { AllCommunityModules } from '@ag-grid-enterprise/all-modules'
import { core } from '@/config/pluginInit'
import { AgGridVue } from 'ag-grid-vue'
import { db } from '@/config/firebase'

export default {
  name: 'Provider',
  mounted () {
    core.index()
    this.onTabchange()
  },
  data () {
    return {
      tabIndex: 0,
      residentsData: [],
      modules: AllCommunityModules,
      paginationPageSize: null,
      idCoProperty: this.$route.params.id,
      dataAllProviders: [],
      conductoresData: [],
      sonidoData: [],
      virtualData: [],
      soporteData: [],
      registroData: [],
      actaData: [],
      conexionData: [],
      dataToShow: [],
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      components: null,
      defaultColDef: { resizable: true },
      groupDefaultExpanded: null,
      rowSelection: null,
      rowData: null,
      getDataPath: null,
      autoGroupColumnDef: null,
      editeItem: '',
      postSort: null,
      inputs1: [
        { name: 'Nombre', type: 'text', dataModel: 'firstName', iconClass: 'fas fa-user-shield', rules: 'required' },
        { name: 'Apellido', type: 'text', dataModel: 'lastName', iconClass: 'fas fa-user-shield', rules: 'required' },
        { name: 'Email', type: 'text', dataModel: 'email', iconClass: 'fas fa-at', rules: 'required||email' },
        { name: 'Numero de Contacto', type: 'number', dataModel: 'phonePrimary', iconClass: 'fas fa-phone-alt', rules: 'required||numeric' },
        { name: 'Pais', type: 'text', dataModel: 'addressCountry', iconClass: 'fas fa-globe-americas', rules: 'required' },
        { name: 'Departamento', type: 'text', dataModel: 'addressDepartment', iconClass: 'fas fa-flag', rules: 'required' },
        { name: 'Ciudad', type: 'text', dataModel: 'addressCity', iconClass: 'far fa-flag', rules: 'required' },
        { name: 'Direccion', type: 'text', dataModel: 'addressAddress', iconClass: 'fas fa-map-marker-alt', rules: 'required' },
        { name: 'Barrio', type: 'text', dataModel: 'addressNeighborhood', iconClass: 'fas fa-home' }
      ]
    }
  },
  computed: {
    getTotalConductores () {
      return this.conductoresData.length
    },
    getTotalSonido () {
      return this.sonidoData.length
    },
    getTotalVirtual () {
      return this.virtualData.length
    },
    getTotalSoporte () {
      return this.soporteData.length
    },
    getTotalRegistro () {
      return this.registroData.length
    },
    getTotalActa () {
      return this.actaData.length
    },
    getTotalConexion () {
      return this.conexionData.length
    },
    getTotalStaff () {
      if (!this.dataAllProviders) {
        return 0
      } else {
        return this.dataAllProviders.length
      }
    }
  },
  components: {
    AgGridVue,
    ModalProperty
    // eslint-disable-next-line vue/no-unused-components
  },
  beforeMount () {
    this.staffHeaders = [
      { headerName: 'Nombre', field: 'name', sortable: true, filter: true, checkboxSelection: true, width: 500 },
      { headerName: 'Email', field: 'email', sortable: true, filter: true },
      { headerName: 'Nit', field: 'nit', sortable: true, filter: true },
      { headerName: 'Telefono', field: 'phone', sortable: true, filter: true },
      { headerName: 'Type', field: 'type', sortable: true, filter: true }
    ]
    this.groupDefaultExpanded = -1
    this.getDataPath = data => {
      return data.names
    }
    this.autoGroupColumnDef = {
      headerName: 'Names ',
      cellRendererParams: { suppressCount: true }
    }
    this.paginationPageSize = 10
  },
  methods: {
    async onTabchange () {
      if (this.tabIndex === 0) {
        this.getStaffIndex()
        this.dataToShow = this.dataAllProviders
      }
      if (this.tabIndex === 1) {
        this.dataToShow = this.conductoresData
      }
      if (this.tabIndex === 2) {
        this.dataToShow = this.sonidoData
      }
      if (this.tabIndex === 3) {
        this.dataToShow = this.registroData
      }
      if (this.tabIndex === 4) {
        this.dataToShow = this.virtualData
      }
      if (this.tabIndex === 5) {
        this.dataToShow = this.soporteData
      }
      if (this.tabIndex === 6) {
        this.dataToShow = this.actaData
      }
      if (this.tabIndex === 7) {
        this.dataToShow = this.conexionData
      }
    },
    async getStaffIndex () {
      this.dataAllProviders = []
      this.conductoresData = []
      this.sonidoData = []
      this.registroData = []
      this.virtualData = []
      this.soporteData = []
      this.actaData = []
      this.conexionData = []
      const vm = this
      var valid = false
      await db.collection('/campus/Vs2FkUx38u3W8IDuABNF/contactsIndex/')
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach(doc => {
              if (doc.data() !== undefined) {
                const data = doc.data()
                Object.values(data).forEach(function (value) {
                  if (value.staff !== undefined) {
                    if (value.staff.conductor) {
                      vm.conductoresData.push(value)
                      valid = true
                    }
                    if (value.staff.sonido) {
                      vm.sonidoData.push(value)
                      valid = true
                    }
                    if (value.staff.registro) {
                      vm.registroData.push(value)
                      valid = true
                    }
                    if (value.staff.virtual) {
                      vm.virtualData.push(value)
                      valid = true
                    }
                    if (value.staff.soporte) {
                      vm.soporteData.push(value)
                      valid = true
                    }
                    if (value.staff.acta) {
                      vm.actaData.push(value)
                      valid = true
                    }
                    if (value.staff.conexion) {
                      vm.conexionData.push(value)
                      valid = true
                    }
                    if (valid) {
                      vm.dataAllProviders.push(value)
                      valid = false
                    }
                  }
                })
              }
            })
          }
        })
    },
    saveModal (status) {
      this.editeItem = ''
      if (status) {
        this.tabIndex = 0
        this.getStaffIndex()
        core.showSnackbar('success', 'Se ha agregado un nuevo Staff con Exito!')
        this.dataToShow = this.dataAllProviders
      } else {
        core.showSnackbar('error', 'El proceso no se pudo completar')
      }
    },
    onRowClickedAllProperties (params) {
      this.$router.push({ path: `/admin-dashboard/staffProfile/${params.data.docId}/` })
      // this.editeItem = params.data.id
    },
    cleanUpdateModal () {
      this.editeItem = ''
    },
    email (params) {
      if (params.colDef.field === 'email') {
        this.$router.push({ path: `/admin-dashboard/ProfileEditCurrent/${params.data.docId}/` })
        console.log(this.$route)
      }
    }
  }
}
</script>

<style >

</style>
