<template>
  <b-container>
  <b-button @click="showModal = !showModal, contactData = {}, selected = []">Agregar/Editar Staff</b-button>
  <b-modal size="lg" v-model="showModal" :title="editeItem === '' ? 'Agregar personal de Staff': 'Editar personal de Staff'">
    <div v-if="initTransaction">
      <loader-process :message="this.messageInformation"></loader-process>
    </div>
    <div v-else>
          <!-- CAMPO Y BOTON PARA BUSCAR POR CEDULA  -->
          <div>
            <b-row>
              <b-col col lg="6" sm="12" class="mb-3">
              <ValidationObserver v-slot="{ handleSubmit }" ref="observerFilter">
                <form @submit.prevent="handleSubmit(filterClient)">
                  <label class="ml-2" for="input-small">Buscar por CEDULA</label>
                  <ValidationProvider name="Valor de" v-slot="{ errors }" >
                    <b-input-group size="sm">
                      <template #prepend>
                        <b-input-group-text >
                          <i class="ri-bookmark-2-line"></i>
                        </b-input-group-text>
                      </template>
                      <b-form-input v-model="contactData.identificationObjectNumber" type="number" id="nitSearchFormControl" placeholder="Ingrese NIT sin digito de verficación" :class="(errors.length > 0 ? ' is-invalid' : '')">
                      </b-form-input>
                      <b-button variant="primary" text class="ml-3" type="submit">
                        Buscar
                        <i class="ri-search-eye-line ml-2" ></i>
                      </b-button>
                      <div class="invalid-feedback ml-2">
                          <span>{{ errors[0] }}</span>
                      </div>
                    </b-input-group>
                    </ValidationProvider>
                  </form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </div>
          <!-- FIN DE CAMPO Y BOTON PARA BUSCAR POR NIT -->
          <!-- INICIO DE CAMPO BUSCAR POR NOMBRE -->
          <label class="ml-2" for="input-small">Buscar por Nombre</label>
          <div class="row">
            <div class="search-wrapper panel-heading col-sm-12">
              <input @click="getNameIndex()" class="form-control" type="text" v-model="searchQueryName" placeholder="Buscar por Nombre" />
            </div>
          </div>
          <div class="table-responsive">
            <table v-if="resourcesName.length" class="table">
              <tbody>
                <tr v-for="item in resultQueryName.slice(0, 5)" :key="item.id">
                  <td><a v-bind:href="item.uri" @click="agregarName(item), filterClient(item.nit)">{{item.name}}</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        <!-- FIN DE CAMPO BUSCAR POR NOMBRE -->
          <ValidationObserver v-slot="{ handleSubmit }" ref="observerContact">
            <b-form @submit.prevent="handleSubmit(confirmModal)">
              <b-row>
                <b-col v-for="input in inputs1" :key="input.index" cols="12" sm="6" class="mb-3">
                  <label class="ml-2" for="input-small">{{input.name}}</label>
                  <!-- Si es texto o numero -->
                  <ValidationProvider :name="input.name" :rules="input.rules" v-slot="{ errors }">
                    <b-input-group v-if="input.type === 'text' || input.type === 'number'" size="sm">
                      <template #prepend>
                        <b-input-group-text >
                          <i :class="input.iconClass"></i>
                        </b-input-group-text>
                      </template>
                      <b-form-input :id="input.name" v-model="contactData[input.dataModel]" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                      <div class="invalid-feedback ml-2">
                        <span>{{ errors[0] }}</span>
                    </div>
                    </b-input-group>
                  </ValidationProvider>
                  <!-- Si es select -->
                  <b-input-group v-if="input.type === 'select'" size="sm">
                    <template #prepend>
                      <b-input-group-text >
                        <i class="ri-bookmark-2-line"></i>
                      </b-input-group-text>
                    </template>
                    <b-form-select
                      :options="input.options" type="text" id="docTypeFormControl" v-model="contactData[input.dataModel]">
                    </b-form-select>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
      <!-- FORMULARIO NEIBE-->
      <template>
        <div>
          <b-form-group label="habilidades:" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              id="checkbox-group-1"
              v-model="selected"
              :options="options"
              :aria-describedby="ariaDescribedby"
              name="flavour-1"
              switches
              stacked
            ></b-form-checkbox-group>
          </b-form-group>
          <div>Selected: <strong>{{ selected }}</strong></div>
        </div>
      </template>
    </div>
      <template #modal-footer>
        <b-button variant="danger" size="sm" class="float-right" @click="showModal=false">
          No
        </b-button>
        <b-button @click="validate" variant="success" size="sm" class="float-right">
          Guardar
        </b-button>
      </template>
  </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { db } from '@/config/firebase'
import LoaderProcess from '@/components/core/loader/LoaderProcess.vue'

export default {
  props: {
    editeItem: { type: String, default: '' },
    inputs1: { type: Array, default: () => [] }
  },
  name: 'ModalStaff',
  data () {
    return {
      selected: [], // Array reference
      options: [
        { value: 'conductor', text: 'Conductor' },
        { value: 'sonido', text: 'Operario de Sonido' },
        { value: 'registro', text: 'Registro de Asistencia' },
        { value: 'virtual', text: 'Asamblea Virtual' },
        { value: 'soporte', text: 'Soporte' },
        { value: 'acta', text: 'Actas' },
        { value: 'conexion', text: 'Conexion' }
      ],
      searchQueryName: null,
      resourcesName: [],
      conductor: false,
      sonido: false,
      registro: false,
      virtual: false,
      soporte: false,
      acta: false,
      conexion: false,
      contactData: {},
      nitFound: false,
      contactSelected: {},
      showModal: false,
      isAddOwner: true,
      showForm: false,
      isDisableForm: false,
      initTransaction: false,
      messageInformation: '',
      searchNames: true,
      dataProperty: {
        idProperty: '',
        atype: '',
        block: '',
        coef: '',
        docIdOwners: {}
      },
      coefTemporal: '',
      dataPropertyDefault: {
        idProperty: '',
        atype: '',
        block: '',
        coef: '',
        docIdOwners: {}
      },
      dataOwner: {
        docRef: '',
        document: '',
        mail: '',
        name: '',
        phones: '',
        profession: ''
      },
      dataOwnerDefault: {
        docRef: '',
        document: '',
        mail: '',
        name: '',
        phones: '',
        profession: ''
      },
      rowData: [],
      typeProperties: [
        { value: 'Propiedad', text: 'Propiedad' },
        { value: 'Cuartoutil', text: 'Cuartoutil' },
        { value: 'Parqueadero', text: 'Parqueadero' },
        { value: 'Local', text: 'Local' },
        { value: 'Oficina', text: 'Oficina' }
      ],
      documentToSearch: '',
      listados: {
        Propiedad: 'apartamentos',
        Cuartoutil: 'cuartoutil',
        Parqueadero: 'parqueadero',
        Local: 'locales',
        Oficina: 'oficina'
      }
    }
  },
  watch: {
    async editeItem (newValue, oldValue) {
      if ((oldValue === '' && newValue !== '') || (newValue !== '' && oldValue !== '')) {
        this.showModal = true
        await db.collection(`co-properties/${this.$route.params.id}/properties`).doc(newValue)
          .get()
          .then((doc) => {
            this.dataProperty.idProperty = doc.data().idProperty
            this.dataProperty.atype = doc.data().atype
            this.dataProperty.coef = doc.data().coef
            this.coefTemporal = doc.data().coef
            this.dataProperty.block = doc.data().block
            for (const id in doc.data().docIdOwners) {
              this.rowData.push(doc.data().docIdOwners[id])
            }
          })
      }
    }
  },
  computed: {
    resultQueryName () {
      if (this.searchQueryName) {
        return this.resourcesName.filter((item) => {
          return this.searchQueryName.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
        })
      } else {
        return this.resourcesName
      }
    }
  },
  components: {
    LoaderProcess
  },
  methods: {
    async validate () {
      this.conductor = false
      this.sonido = false
      this.registro = false
      this.virtual = false
      this.soporte = false
      this.acta = false
      this.conexion = false
      for (let index = 0; index < this.selected.length; index++) {
        switch (this.selected[index]) {
          case 'conductor':
            this.conductor = true
            break
          case 'sonido':
            this.sonido = true
            break
          case 'registro':
            this.registro = true
            break
          case 'virtual':
            this.virtual = true
            break
          case 'soporte':
            this.soporte = true
            break
          case 'acta':
            this.acta = true
            break
          case 'conexion':
            this.conexion = true
            break
        }
      }
      var newData = {
        docId: this.contactSelected.docId,
        email: this.contactData.email,
        id: this.contactSelected.id,
        index: this.contactSelected.index,
        name: this.contactData.firstName + ' ' + this.contactData.lastName,
        firstName: this.contactData.firstName,
        lastName: this.contactData.lastName,
        nit: this.contactData.identificationObjectNumber,
        phone: this.contactData.phonePrimary,
        staff: {
          conductor: this.conductor,
          sonido: this.sonido,
          registro: this.registro,
          virtual: this.virtual,
          soporte: this.soporte,
          acta: this.acta,
          conexion: this.conexion
        },
        type: 'provider'
      }
      await db.collection('campus/Vs2FkUx38u3W8IDuABNF/contactsIndex')
        .doc(`contactsIndex${this.contactSelected.index}`)
        .update({
          [this.contactSelected.docId]: newData
        }).then((indexDoc) => {
          console.log('Actualizado en el index')
          this.makeToast('success', 'Contacto actualizado en Sistevot')
          this.$emit('saveModal', true)
          db.collection('/campus/Vs2FkUx38u3W8IDuABNF/contacts/').doc(this.contactSelected.docId).update(newData)
            .then(() => {
              console.log('exito')
              this.cleanModal()
            })
          this.showModal = false
        }).catch((error) => {
          console.log('Error actualizando el indice', error)
          this.makeToast('danger', 'Error al actualizar contacto en Sistevot. ', error)
        })
    },
    getContactsIndex () {
      const vm = this
      this.contactSelected.index = null
      db.collection('campus/Vs2FkUx38u3W8IDuABNF/contactsIndex')
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach(doc => {
              if (doc.data() !== undefined) {
                const data = doc.data()
                Object.values(data).forEach(function (value) {
                  if (value.nit === vm.contactData.identificationObjectNumber) {
                    console.log('busqueda desde el indice', value.docId)
                    vm.contactSelected.index = value.index
                    vm.contactSelected.docId = value.docId
                    vm.contactSelected.id = value.id
                    vm.contactSelected.type = value.type
                  }
                  // vm.contactsData.push(value)
                })
              }
            })
          }
        })
    },
    async filterClient () {
      this.getContactsIndex()
      console.log('entre al filter')
      const valid = await this.$refs.observerFilter.validate()
      if (valid) {
        await db.collection('campus/Vs2FkUx38u3W8IDuABNF/contacts')
          .where('identificationObject.number', '==', this.contactData.identificationObjectNumber)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              // this.nitFound = true
              querySnapshot.forEach((doc) => {
                console.log('querySnapshot', doc.data())
                this.contactSelected.docId = doc.id
                // this.nitSearched = doc
                var obj = {}
                // *************Formateo de informacion
                obj.addressCountry = doc.data().address.country
                obj.addressDepartment = doc.data().address.department
                obj.addressCity = doc.data().address.city
                obj.addressAddress = doc.data().address.address
                obj.addressNeighborhood = doc.data().address.neighborhood
                obj.addressSector = doc.data().address.sector
                obj.identificationObjectNumber = doc.data().identificationObject.number
                obj.identificationObjectDv = doc.data().identificationObject.dv
                obj.identificationObjectType = doc.data().identificationObject.type
                if (doc.data().identificationObject.type === 'CC') {
                  obj.kindOfPerson = 'PERSON_ENTITY'
                } else if (doc.data().identificationObject.type === 'NIT') {
                  obj.kindOfPerson = 'LEGAL_ENTITY'
                }
                obj.email = doc.data().email
                obj.id = doc.data().id
                obj.name = doc.data().name
                obj.firstName = doc.data().firstName
                obj.lastName = doc.data().lastName
                obj.nameAdmin = doc.data().nameAdmin
                obj.phonePrimary = doc.data().phonePrimary
                obj.client = doc.data().clientType

                if (doc.data().staff) {
                  if (doc.data().staff.conductor === true) { this.selected.push('conductor') }
                  if (doc.data().staff.sonido === true) { this.selected.push('sonido') }
                  if (doc.data().staff.registro === true) { this.selected.push('registro') }
                  if (doc.data().staff.virtual === true) { this.selected.push('virtual') }
                  if (doc.data().staff.soporte === true) { this.selected.push('soporte') }
                  if (doc.data().staff.acta === true) { this.selected.push('acta') }
                  if (doc.data().staff.conexion === true) { this.selected.push('conexion') }
                }

                // Proceso para eliminar los null
                Object.keys(obj).forEach(function (key) {
                  if (obj[key] === null || obj[key] === undefined) {
                    obj[key] = '-'
                  }
                })
                // this.crudAction(obj, 'update')
                this.contactData = obj
                console.log('contact data', this.contactData)
                this.data = obj
                this.nitFound = true
              })
            } else {
              // TODO: Debo mostar una alerta informativa
              this.makeToast('danger', 'Contacto no encontrado')
              this.nitFound = false
              // this.nitFound = false
            }
          })
      } else {
        console.log(this.data.dataClient.nit)
      }
    },
    cleanModal () {
      this.dataProperty = Object.assign({}, this.dataPropertyDefault)
      this.documentToSearch = ''
      this.rowData = []
      this.showModal = false
      this.dataOwner = Object.assign({}, this.dataOwnerDefault)
      this.isAddOwner = true
      this.isDisableForm = false
      this.showForm = false
      this.documentToSearch = ''
      this.resourcesName = []
      this.searchQueryName = null
      this.searchNames = true
    },
    async toggleModal (state) {
      if (state === 1) {
        this.cleanModal()
        this.showModal = false
      } else {
        if (state === 2) {
          const valid = await this.$refs.observer.validate()
          if (valid) {
            if (this.rowData.length >= 1) {
              this.initTransaction = true
              this.messageInformation = 'GUARDANDO NUEVA PROPIEDAD'
              const object = {}
              // Creo los mapas para docIdOwners con respecto a rowData
              for (let i = 0; i < this.rowData.length; i++) {
                object[`${this.rowData[i].document}`] = this.rowData[i]
              }
              this.dataProperty.docIdOwners = object
              const propertiesRef = db.collection('co-properties/' + this.$route.params.id + '/properties').doc()
              const superIndexRef = db.collection('co-properties/' + this.$route.params.id + '/properties').doc('propiedades')
              const coPropertyRef = db.collection('co-properties/').doc(this.$route.params.id)
              const superIndex = db.collection('co-properties/' + this.$route.params.id + '/properties').doc(`listado-${this.listados[this.dataProperty.atype]}`)
              let dataCoProperty = {}
              db.runTransaction(async transaction => {
                let coefCoproperty = 0
                // Obtengo la informacion de la copriedad basic_data
                await coPropertyRef
                  .get()
                  .then(doc => {
                    dataCoProperty = doc.data()
                    coefCoproperty = parseFloat(doc.data().basic_data.totalCoef)
                  })
                // Creo la transancion para una nueva propiedad
                transaction.set(propertiesRef, this.dataProperty)
                const newProperty = {}
                this.dataProperty.id = propertiesRef.id
                newProperty[`${propertiesRef.id}`] = this.dataProperty
                transaction.update(superIndexRef, newProperty)
                coefCoproperty = coefCoproperty + parseFloat(this.dataProperty.coef)
                dataCoProperty.basic_data.totalCoef = (coefCoproperty.toFixed(4)).toString()
                transaction.update(coPropertyRef, { basic_data: dataCoProperty.basic_data })
                transaction.update(superIndex, newProperty)
              }).then(() => {
                this.initTransaction = false
                this.cleanModal()
                this.$emit('saveModal', true)
              }).catch(() => {
                this.initTransaction = false
                core.showSnackbar('error', 'Ha ocurrido un error no reconcido comunicar el error con codigo MP:317')
              })
            } else {
              core.showSnackbar('error', 'Error: La propiedad debe tener como minimo 1 propietario relacionado.')
            }
          } else {
            console.log('no valid')
          }
        } else {
          if (state === 3) {
            this.cleanModal()
            this.showModal = false
            this.$emit('cleanUpdateModal')
          } else {
            if (state === 4) {
              const valid = await this.$refs.observer.validate()
              if (valid) {
                if (this.rowData.length >= 1) {
                  this.initTransaction = true
                  this.messageInformation = 'ACTUALIZANDO INFORMACION DE LA PROPIEDAD'
                  const object = {}
                  for (let i = 0; i < this.rowData.length; i++) {
                    object[`${this.rowData[i].document}`] = this.rowData[i]
                  }
                  this.dataProperty.docIdOwners = object
                  const propertiesRef = db.collection('co-properties/' + this.$route.params.id + '/properties').doc(this.editeItem)
                  const superIndexRef = db.collection('co-properties/' + this.$route.params.id + '/properties').doc('propiedades')
                  const superIndexListRef = db.collection('co-properties/' + this.$route.params.id + '/properties').doc(`listado-${this.listados[this.dataProperty.atype]}`)
                  const coPropertyRef = db.collection('co-properties/').doc(this.$route.params.id)
                  db.runTransaction(async transaction => {
                    /**
                     * Consulto la copropiedad para actualizar el coeficiente
                     */
                    let dataCoProperty = {}
                    await coPropertyRef
                      .get()
                      .then(doc => {
                        dataCoProperty = doc.data()
                      })
                    dataCoProperty.basic_data.totalCoef = ((parseFloat(dataCoProperty.basic_data.totalCoef) - parseFloat(this.coefTemporal)) + parseFloat(this.dataProperty.coef)).toFixed(4)
                    transaction.update(coPropertyRef, { basic_data: dataCoProperty.basic_data })
                    /**
                     * Actualizo la propiedad
                     */
                    transaction.update(propertiesRef, this.dataProperty)
                    /**
                     * Actualizo super indice general
                     */
                    const newProperty = {}
                    this.dataProperty.id = this.editeItem
                    newProperty[`${this.editeItem}`] = this.dataProperty
                    transaction.update(superIndexRef, newProperty)
                    transaction.update(superIndexListRef, newProperty)
                  }).then(() => {
                    this.initTransaction = false
                    this.cleanModal()
                    this.$emit('saveModal', true)
                  }).catch(() => {
                    this.initTransaction = false
                    core.showSnackbar('error', 'Ha ocurrido un error no reconcido comunicar el error con codigo MP:317')
                  })
                } else {
                  core.showSnackbar('error', 'Error: La propiedad debe tener como minimo 1 propietario relacionado.')
                }
              } else {
                console.log('no valid')
              }
            }
          }
        }
      }
    },
    confirmData () {
      console.log('Confirmar')
    },
    addOwner () {
      this.isAddOwner = false
    },
    cancel () {
      this.isAddOwner = true
      this.showForm = false
      this.isDisableForm = false
      this.documentToSearch = ''
    },
    async verifyOwner () {
      this.dataOwner = Object.assign({}, this.dataOwnerDefault)
      console.log('verificarOwner ')
      this.isDisableForm = false
      this.showForm = false
      if (this.documentToSearch.length < 4) {
        core.showSnackbar('success', 'Ingrese un numero de documento valido por favor')
      } else {
        this.showForm = true
        db.collection('owner').where('document', '==', this.documentToSearch)
          .get()
          .then(querySnapshot => {
            if (!querySnapshot.empty) {
              core.showSnackbar('success', 'El usuario ya esta creado en nuestra base de datos.')
              querySnapshot.forEach(doc => {
                this.dataOwner.document = doc.data().document
                this.dataOwner.mail = doc.data().mail
                this.dataOwner.name = doc.data().name
                this.dataOwner.phones = doc.data().phones
                this.dataOwner.profession = doc.data().profession
                this.dataOwner.uid = doc.data().uid
                this.dataOwner.docRef = doc.id
              })
            } else {
              core.showSnackbar('success', 'El usuario no esta creado en base de datos, ingrese los datos para crearlo.')
              this.dataOwner.document = this.documentToSearch
              this.dataOwner.mail = ''
              this.dataOwner.name = ''
              this.dataOwner.phones = ''
              this.dataOwner.profession = ''
              this.dataOwner.uid = ''
            }
          })
          .finally(() => {
            if (this.dataOwner.docRef !== '') {
              this.isDisableForm = true
            } else {
              this.isDisableForm = false
            }
          })
      }
    },
    async addOwnerInProperty () {
      const valid = await this.$refs.observer2.validate()
      if (valid) {
        const objectAdd = this.dataOwner
        console.log(objectAdd)
        if (objectAdd.docRef !== '') {
          this.rowData.push(objectAdd)
          core.showSnackbar('success', 'Se agregado un usuario a la propiedad')
        } else {
          await db.collection('owner').add(objectAdd)
            .then(doc => {
              objectAdd.docRef = doc.id
              this.rowData.push(objectAdd)
              core.showSnackbar('success', 'El nuevo propietario se ha creado satisfactoriamente')
            })
            .catch(error => {
              console.log(error)
              core.showSnackbar('error', 'Ha ocurrido un error, intentelo de nuevo, si el problema continua comuniquelo al area encargada.')
            })
        }
        this.dataOwner = Object.assign({}, this.dataOwnerDefault)
        this.isDisableForm = false
        this.showForm = false
        this.documentToSearch = ''
      } else {
        core.showSnackbar('success', 'Debe completar los campos obligatorios')
      }
    },
    deleteRegister (index) {
      console.log(index)
      this.$bvModal.msgBoxConfirm('Deseas quitar este propietario de esta propiedad?')
        .then(value => {
          if (value) {
            console.log('Eliminar')
            this.rowData.splice(index, 1)
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    makeToast (variant = null, message) {
      var title1 = ''
      if (variant === 'success') {
        title1 = 'Proceso exitoso'
      } else if (variant === 'danger') {
        title1 = 'Proceso fallido'
      }
      this.$bvToast.toast(message, {
        title: title1,
        variant: variant,
        solid: true,
        autoHideDelay: 10000
      })
    },
    getNameIndex () {
      if (this.searchNames) {
        this.searchNames = false
        const vm = this
        db.collection('campus/Vs2FkUx38u3W8IDuABNF/contactsIndex')
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach(doc => {
                if (doc.data() !== undefined) {
                  const data = doc.data()
                  Object.values(data).forEach(function (value) {
                    if (value.name != null) {
                      vm.resourcesName.push(value)
                    }
                  })
                }
              })
            }
          })
      }
    },
    agregarName (item) {
      // const index = this.rows.indexOf(item)
      this.searchQueryName = item.name
      this.contactData.identificationObjectNumber = item.nit
      console.log(this.contactData.identificationObjectNumber)
    }
  }
}
</script>
<style lang="scss" scoped>
.objective-icon{
  p {
    transition: 1s;
  }
}
.objective-icon:hover {
  i {
    color: rgb(13, 42, 102);
  }
  p {
    transition: 1s;
    transform: translate(8px, 0px);
    color:  rgb(31, 41, 61);;
  }
}
</style>
